<template>
<div style="width:100%">
  <v-card v-if="this.objAnual.casosTotales">
    <v-card-text>
      <v-toolbar :color="colores.primario" dark dense><span style="font-size:20px">CASOS ABIERTOS</span></v-toolbar>
    </v-card-text>
    <v-card-text>
      <v-row no-gutters>
          <v-col cols="12" md="4" align="center" align-self="center">
              <v-row no-gutters>
                <v-col cols="12" md="3" align="center" align-self="center" class="pa-1"><v-card dark color="green">0</v-card></v-col>
                <v-col cols="12" md="3" align="center" align-self="center" class="pa-1"><v-card dark color="green">1</v-card></v-col>
                <v-col cols="12" md="3" align="center" align-self="center" class="pa-1"><v-card dark color="green">2</v-card></v-col>
                <v-col cols="12" md="3" align="center" align-self="center" class="pa-1"><v-card dark color="green">3</v-card></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" md="4" align="center" align-self="center">
            <v-row no-gutters>
                <v-col cols="12" md="3" align="center" align-self="center" class="pa-1"><v-card dark color="amber">4</v-card></v-col>
                <v-col cols="12" md="3" align="center" align-self="center" class="pa-1"><v-card dark color="amber">5</v-card></v-col>
                <v-col cols="12" md="3" align="center" align-self="center" class="pa-1"><v-card dark color="amber">6</v-card></v-col>
                <v-col cols="12" md="3" align="center" align-self="center" class="pa-1"><v-card dark color="amber">7</v-card></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" md="4" align="center" align-self="center">
            <v-row no-gutters>
                <v-col cols="12" md="3" align="center" align-self="center" class="pa-1"><v-card dark color="red">8</v-card></v-col>
                <v-col cols="12" md="3" align="center" align-self="center" class="pa-1"><v-card dark color="red">9</v-card></v-col>
                <v-col cols="12" md="3" align="center" align-self="center" class="pa-1"><v-card dark color="red">10</v-card></v-col>
                <v-col cols="12" md="3" align="center" align-self="center" class="pa-1"><v-card dark color="red">+11</v-card></v-col>
              </v-row>
          </v-col>
      </v-row>
      <v-row no-gutters>
          <v-col cols="12" md="4" align="center" align-self="center">
              <v-row no-gutters>
                <v-col cols="12" md="3" align="center" align-self="center">{{ organizarDataAbiertos(0) }}</v-col>
                <v-col cols="12" md="3" align="center" align-self="center">{{ organizarDataAbiertos(1) }}</v-col>
                <v-col cols="12" md="3" align="center" align-self="center">{{ organizarDataAbiertos(2) }}</v-col>
                <v-col cols="12" md="3" align="center" align-self="center">{{ organizarDataAbiertos(3) }}</v-col>
              </v-row>
          </v-col>
          <v-col cols="12" md="4" align="center" align-self="center">
            <v-row no-gutters>
              <v-col cols="12" md="3" align="center" align-self="center">{{ organizarDataAbiertos(4) }}</v-col>
              <v-col cols="12" md="3" align="center" align-self="center">{{ organizarDataAbiertos(5) }}</v-col>
              <v-col cols="12" md="3" align="center" align-self="center">{{ organizarDataAbiertos(6) }}</v-col>
              <v-col cols="12" md="3" align="center" align-self="center">{{ organizarDataAbiertos(7) }}</v-col>
              </v-row>
          </v-col>
          <v-col cols="12" md="4" align="center" align-self="center">
            <v-row no-gutters>
              <v-col cols="12" md="3" align="center" align-self="center">{{ organizarDataAbiertos(8) }}</v-col>
              <v-col cols="12" md="3" align="center" align-self="center">{{ organizarDataAbiertos(9) }}</v-col>
              <v-col cols="12" md="3" align="center" align-self="center">{{ organizarDataAbiertos(10) }}</v-col>
              <v-col cols="12" md="3" align="center" align-self="center">{{ organizarDataAbiertos(11) }}</v-col>
              </v-row>
          </v-col>
      </v-row>
      <v-row no-gutters>
          <v-col cols="12" md="3" align="center" align-self="center">
            <v-card :color="colores.primario" dark class="pa-1">
              TOTAL: {{ organizarDataAbiertos(-1) }}
            </v-card>
          </v-col>
          <v-col cols="12" md="3" align="center" align-self="center">
            <v-btn @click="generar_excelAbiertos" :color="colores.green" dark :loading="loadingExcel1">EXCEL ABIERTOS</v-btn>
          </v-col>
      </v-row>
    </v-card-text>

  </v-card>

  <br>
  <v-card v-if="this.objAnual.casosTotales">
    <v-card-text>
      <v-toolbar :color="colores.primario" dark dense><span style="font-size:20px">CASOS ABIERTOS EN CALAMIENTOS</span></v-toolbar>
    </v-card-text>
    <v-card-text>
      <v-row no-gutters>
          <v-col cols="12" md="3" align="center" align-self="center">
            ESCALAMIENTOS
          </v-col>
          <v-col cols="12" md="3" align="center" align-self="center">
              <v-row no-gutters>
                <v-col cols="12" md="3" align="center" align-self="center" class="pa-1"><v-card dark color="green">0</v-card></v-col>
                <v-col cols="12" md="3" align="center" align-self="center" class="pa-1"><v-card dark color="green">1</v-card></v-col>
                <v-col cols="12" md="3" align="center" align-self="center" class="pa-1"><v-card dark color="green">2</v-card></v-col>
                <v-col cols="12" md="3" align="center" align-self="center" class="pa-1"><v-card dark color="green">3</v-card></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" md="3" align="center" align-self="center">
            <v-row no-gutters>
                <v-col cols="12" md="3" align="center" align-self="center" class="pa-1"><v-card dark color="amber">4</v-card></v-col>
                <v-col cols="12" md="3" align="center" align-self="center" class="pa-1"><v-card dark color="amber">5</v-card></v-col>
                <v-col cols="12" md="3" align="center" align-self="center" class="pa-1"><v-card dark color="amber">6</v-card></v-col>
                <v-col cols="12" md="3" align="center" align-self="center" class="pa-1"><v-card dark color="amber">7</v-card></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" md="3" align="center" align-self="center">
            <v-row no-gutters>
                <v-col cols="12" md="3" align="center" align-self="center" class="pa-1"><v-card dark color="red">8</v-card></v-col>
                <v-col cols="12" md="3" align="center" align-self="center" class="pa-1"><v-card dark color="red">9</v-card></v-col>
                <v-col cols="12" md="3" align="center" align-self="center" class="pa-1"><v-card dark color="red">10</v-card></v-col>
              </v-row>
          </v-col>
      </v-row>
      <v-row no-gutters>
          <v-col cols="12" md="3" align="center" align-self="center">
            INTERNO
          </v-col>
          <v-col cols="12" md="3" align="center" align-self="center">
              <v-row no-gutters>
                <v-col cols="12" md="3" align="center" align-self="center">{{ organizarDataEscaInt(0) }}</v-col>
                <v-col cols="12" md="3" align="center" align-self="center">{{ organizarDataEscaInt(1) }}</v-col>
                <v-col cols="12" md="3" align="center" align-self="center">{{ organizarDataEscaInt(2) }}</v-col>
                <v-col cols="12" md="3" align="center" align-self="center">{{ organizarDataEscaInt(3) }}</v-col>
              </v-row>
          </v-col>
          <v-col cols="12" md="3" align="center" align-self="center">
            <v-row no-gutters>
                <v-col cols="12" md="3" align="center" align-self="center">{{ organizarDataEscaInt(4) }}</v-col>
                <v-col cols="12" md="3" align="center" align-self="center">{{ organizarDataEscaInt(5) }}</v-col>
                <v-col cols="12" md="3" align="center" align-self="center">{{ organizarDataEscaInt(6) }}</v-col>
                <v-col cols="12" md="3" align="center" align-self="center">{{ organizarDataEscaInt(7) }}</v-col>
              </v-row>
          </v-col>
          <v-col cols="12" md="3" align="center" align-self="center">
            <v-row no-gutters>
                <v-col cols="12" md="3" align="center" align-self="center">{{ organizarDataEscaInt(8) }}</v-col>
                <v-col cols="12" md="3" align="center" align-self="center">{{ organizarDataEscaInt(9) }}</v-col>
                <v-col cols="12" md="3" align="center" align-self="center">{{ organizarDataEscaInt(10) }}</v-col>
              </v-row>
          </v-col>
      </v-row>
      <v-row no-gutters>
          <v-col cols="12" md="3" align="center" align-self="center">
            OTRO
          </v-col>
          <v-col cols="12" md="3" align="center" align-self="center">
              <v-row no-gutters>
                <v-col cols="12" md="3" align="center" align-self="center">{{ organizarDataEscaOtro(0) }}</v-col>
                <v-col cols="12" md="3" align="center" align-self="center">{{ organizarDataEscaOtro(1) }}</v-col>
                <v-col cols="12" md="3" align="center" align-self="center">{{ organizarDataEscaOtro(2) }}</v-col>
                <v-col cols="12" md="3" align="center" align-self="center">{{ organizarDataEscaOtro(3) }}</v-col>
              </v-row>
          </v-col>
          <v-col cols="12" md="3" align="center" align-self="center">
            <v-row no-gutters>
                <v-col cols="12" md="3" align="center" align-self="center">{{ organizarDataEscaOtro(4) }}</v-col>
                <v-col cols="12" md="3" align="center" align-self="center">{{ organizarDataEscaOtro(5) }}</v-col>
                <v-col cols="12" md="3" align="center" align-self="center">{{ organizarDataEscaOtro(6) }}</v-col>
                <v-col cols="12" md="3" align="center" align-self="center">{{ organizarDataEscaOtro(7) }}</v-col>
              </v-row>
          </v-col>
          <v-col cols="12" md="3" align="center" align-self="center">
            <v-row no-gutters>
                <v-col cols="12" md="3" align="center" align-self="center">{{ organizarDataEscaOtro(8) }}</v-col>
                <v-col cols="12" md="3" align="center" align-self="center">{{ organizarDataEscaOtro(9) }}</v-col>
                <v-col cols="12" md="3" align="center" align-self="center">{{ organizarDataEscaOtro(10) }}</v-col>
              </v-row>
          </v-col>
      </v-row>
      <v-row >
        <v-col cols="12" md="3" align="center" align-self="center">
            <v-card :color="colores.primario" dark class="pa-1">
                INTERNO: {{ organizarDataEscaInt(-1) }}
            </v-card>
        </v-col>
        <v-col cols="12" md="3" align="center" align-self="center">
            <v-card :color="colores.primario" dark class="pa-1">
              TOTAL: {{ organizarDataEscaOtro(-1) }}
            </v-card>
          <!-- <v-btn @click="generarExcel('Abiertos')" :color="colores.primario" dark>EXCEL ESCALAMIENTOS</v-btn>&nbsp; -->
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  <br>
</div>
</template>
<script>
import recFunciones from '@/js/rec_funciones.js'
import axios from 'axios'

export default {
  name: 'estadisticas_totalesComponent',
  components: {
  },
  mixins: [recFunciones],
  props: ['colores', 'objAnual'],
  data: () => ({
    loadingExcel1: false
  }), // FIN DE DATA
  created () {

  }, // FIN DE CREATED
  watch: {}, // FIN DE WATCH
  methods: {
    organizarDataAbiertos (col) {
      const cantidadF = this.dataJS(col)
      if (cantidadF === undefined) {
        return 0
      } else if (cantidadF > -1) {
        return cantidadF
      }
    },
    organizarDataEscaInt (col) {
      const cantidadF = this.dataEscaIntJS(col)
      if (cantidadF === undefined) {
        return 0
      } else if (cantidadF > -1) {
        return cantidadF
      }
    },
    organizarDataEscaOtro (col) {
      const cantidadF = this.dataEscaOtroJS(col)
      if (cantidadF === undefined) {
        return 0
      } else if (cantidadF > -1) {
        return cantidadF
      }
    },
    dataJS (col) {
      const element1 = this.objAnual.casosTotales
      for (let index = 0; index < Object.values(element1).length; index++) {
        const element2 = element1[index]
        if (index === col) {
          return element2
        }
        if (col === -1) {
          return element1.total
        }
      }
    },
    dataEscaIntJS (col) {
      const element1 = this.objAnual.dataCasosEcsTotales.casosTotalesEscInt
      for (let index = 0; index < Object.values(element1).length; index++) {
        const element2 = element1[index]
        if (index === col) {
          return element2
        }
        if (col === -1) {
          return element1.total
        }
      }
    },
    dataEscaOtroJS (col) {
      const element1 = this.objAnual.dataCasosEcsTotales.casosTotalesEscOtro
      for (let index = 0; index < Object.values(element1).length; index++) {
        const element2 = element1[index]
        if (index === col) {
          return element2
        }
        if (col === -1) {
          return element1.total
        }
      }
    },
    generar_excelAbiertos () {
      const anio = this.anio
      this.loadingExcel1 = true
      const urlroute = this.$store.getters.getUrl + 'excelAbiertos/' + anio
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        },
        responseType: 'blob' // importante
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        let today = new Date().toLocaleString('en-CO', { timeZone: 'America/Bogota', hour12: false })
        const reg = /\//g
        today = today.replace(reg, '-')
        this.esperar = false
        link.setAttribute('download', 'Abiertos_' + today + '.xlsx')
        document.body.appendChild(link)
        link.click()
        this.item = ''
        this.date = ''
        this.date2 = ''
      }).catch(error => {
        console.log('error ' + error)
        this.text_snack = []
        this.snackbar_error = true
      }).finally(() => {
        this.loadingExcel1 = false
      })
    }

  } // FIN DE METODOS
}
</script>
