<template>
<div style="width:100%">
  <v-container fluid>
    <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field v-model="anio" label="AÑO" type="number"></v-text-field>
            </v-col>
            <v-col cols="12" md="1">
              <v-btn :loading="loadingAnual" dark :color="colores.primario" @click="anual" block><v-icon color="white">mdi-refresh</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-card-text>
    </v-card>
    <br>
    <v-card>
        <v-card-text>
            <v-row no-gutters>
              <v-col cols="12" md="6">
                <v-row no-gutters>
                  <v-col cols="12" md="3" class="pa-1">
                    <v-card color="brown" dark class="text-center" style="font-size: 10px;">MICROSITIO</v-card>
                    <div class="text-center">{{ rec_function_numberWithCommas(general(7), ',') }}</div>
                  </v-col>
                  <v-col cols="12" md="3" class="pa-1">
                    <v-card color="indigo" dark class="text-center" style="font-size: 10px;">CORREO</v-card>
                    <div class="text-center">{{ rec_function_numberWithCommas(general(8), ',') }}</div>
                  </v-col>
                  <v-col cols="12" md="3" class="pa-1">
                    <v-card color="pink" dark class="text-center" style="font-size: 10px;">SFC</v-card>
                    <div class="text-center">{{ rec_function_numberWithCommas(general(9), ',') }}</div>
                  </v-col>
                  <v-col cols="12" md="3" class="pa-1">
                    <v-card color="blue" dark class="text-center" style="font-size: 10px;">ASIG. ANALISTA</v-card>
                    <div class="text-center">{{ rec_function_numberWithCommas(general(2), ',') }}</div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-row no-gutters>
                  <v-col cols="12" md="3" class="pa-1">
                    <v-card color="amber" dark class="text-center" style="font-size: 10px;">ESCALAMIENTO</v-card>
                    <div class="text-center">{{ rec_function_numberWithCommas(general(3), ',') }}</div>
                  </v-col>
                  <v-col cols="12" md="3" class="pa-1">
                    <v-card color="deep-purple lighten" dark class="text-center" style="font-size: 10px;">EN PRORROGA</v-card>
                    <div class="text-center">{{ rec_function_numberWithCommas(general(10), ',') }}</div>
                  </v-col>
                  <v-col cols="12" md="3" class="pa-1">
                    <v-card color="purple" dark class="text-center" style="font-size: 10px;">CERRADO DUPLICADO</v-card>
                    <div class="text-center">{{ rec_function_numberWithCommas(general(4), ',') }}</div>
                  </v-col>
                  <v-col cols="12" md="3" class="pa-1">
                    <v-card color="green" dark class="text-center" style="font-size: 10px;">CERRADO</v-card>
                    <div class="text-center">{{ rec_function_numberWithCommas(general(6), ',') }}</div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
        </v-card-text>
      <v-card-text>
        <div >
          <v-row no-gutters>
              <v-col cols="12" md="6" v-for="(anual, index1) in objAnual.anual" :key="index1">
                <v-row no-gutters>
                    <v-col cols="12" md="1" align="center" align-self="center">
                      <v-avatar :color="colores.primario" size="36" class="white--text" style="font-size:14px">{{ anual.mes.texto }}</v-avatar>
                    </v-col>
                    <v-col cols="12" md="11" >
                      <v-row no-gutters>
                        <v-col cols="12" md="3" style="font-size:10px">TIPO SOLICITUD</v-col>
                        <v-col cols="12" md="1" ><v-card color="white" class="text-center" style="font-size: 10px;">TOTAL</v-card></v-col>
                        <v-col cols="12" md="1" ><v-card color="brown" dark class="text-center" style="font-size: 10px;">MIC</v-card></v-col>
                        <v-col cols="12" md="1" ><v-card color="indigo" dark class="text-center" style="font-size: 10px;">COR</v-card></v-col>
                        <v-col cols="12" md="1" ><v-card color="pink" dark class="text-center" style="font-size: 10px;">SFC</v-card></v-col>
                        <v-col cols="12" md="1" ><v-card color="blue" dark class="text-center" style="font-size: 10px;">ASI</v-card></v-col>
                        <v-col cols="12" md="1" ><v-card color="amber" dark class="text-center" style="font-size: 10px;">ESC</v-card></v-col>
                        <v-col cols="12" md="1" ><v-card color="deep-purple lighten" dark class="text-center" style="font-size: 10px;">PRO</v-card></v-col>
                        <v-col cols="12" md="1" ><v-card color="purple" dark class="text-center" style="font-size: 10px;">DUP</v-card></v-col>
                        <v-col cols="12" md="1" ><v-card color="green" dark class="text-center" style="font-size: 10px;">CER</v-card></v-col>
                      </v-row>
                      <div style="height: 5px;"></div>
                      <v-divider></v-divider>
                      <div v-for="(gestion, index2) in anual.gestionses" :key="index2">
                          <v-row no-gutters>
                            <v-col cols="12" md="3" style="font-size:10px">{{ gestion.descripcion }}</v-col>
                            <v-col cols="12" md="1" style="font-size:10px" class="text-center">{{ gestion.total === 0 ? ''        : rec_function_numberWithCommas(gestion.total, ',') }}</v-col>
                            <v-col cols="12" md="1" style="font-size:10px" class="text-center">{{ gestion.micrositio === 0 ? ''   : rec_function_numberWithCommas(gestion.micrositio, ',') }}</v-col>
                            <v-col cols="12" md="1" style="font-size:10px" class="text-center">{{ gestion.correo === 0 ? ''       : rec_function_numberWithCommas(gestion.correo, ',') }}</v-col>
                            <v-col cols="12" md="1" style="font-size:10px" class="text-center">{{ gestion.sfc === 0 ? ''          : rec_function_numberWithCommas(gestion.sfc, ',') }}</v-col>
                            <v-col cols="12" md="1" style="font-size:10px" class="text-center">{{ gestion.analista === 0 ? ''     : rec_function_numberWithCommas(gestion.analista, ',') }}</v-col>
                            <v-col cols="12" md="1" style="font-size:10px" class="text-center">{{ gestion.escalamiento === 0 ? '' : rec_function_numberWithCommas(gestion.escalamiento, ',') }}</v-col>
                            <v-col cols="12" md="1" style="font-size:10px" class="text-center">{{ gestion.prorroga === 0 ? ''     : rec_function_numberWithCommas(gestion.prorroga, ',') }}</v-col>
                            <v-col cols="12" md="1" style="font-size:10px" class="text-center">{{ gestion.duplicado === 0 ? ''    : rec_function_numberWithCommas(gestion.duplicado, ',') }}</v-col>
                            <v-col cols="12" md="1" style="font-size:10px" class="text-center">{{ gestion.cerrado === 0 ? ''      : rec_function_numberWithCommas(gestion.cerrado, ',') }}</v-col>
                          </v-row>
                          <v-divider></v-divider>
                      </div>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12" md="1" align="center" align-self="center"></v-col>
                    <v-col cols="12" md="11" align="center" align-self="center">
                      <v-btn :loading="loadingMes" x-small dark :color="selMes.numero === anual.mes.numero ? colores.primario : colores.black" @click="mes(anual.mes)" block><v-icon size="15">mdi-chart-areaspline</v-icon>VER DETALLE DEL MES DE {{ anual.mes.descripcion }}</v-btn>
                    </v-col>
                </v-row>
                <br>
              </v-col>
            </v-row>
        </div>
      </v-card-text>
    </v-card>
    <br>
    <totalesComponent :colores="colores" :objAnual="objAnual"></totalesComponent>
    <mesComponent v-if="objMes.data" :colores="colores" :objMes="objMes" :selMes="selMes" :anio="anio"></mesComponent>
  </v-container>
</div>
</template>
<script>
import recFunciones from '@/js/rec_funciones.js'
import recEstadisticasNew from '@/js/rec_estadisticasnew.js'
import totalesComponent from '@/components/estadisticas/estadisticas_totalesComponent.vue'
import mesComponent from '@/components/estadisticas/estadisticas_mesComponent.vue'
export default {
  name: 'estadisticasComponent',
  components: {
    totalesComponent,
    mesComponent
  },
  props: ['session', 'colores', 'rules', 'items'],
  mixins: [recFunciones, recEstadisticasNew],
  data: () => ({
    anio: new Date().getFullYear(),
    loadingAnual: false,
    objAnual: { general: [], anual: [], casosTotales: null, dataCasosEcsTotales: null },
    selMes: { mes: '', numero: 0, texto: '', descripcion: '' },
    objMes: { token: 0, data: null },
    loadingMes: false
  }), // FIN DE DATA
  created () {

  }, // FIN DE CREATED
  watch: {}, // FIN DE WATCH
  methods: {
    anual () {
      this.rec_anualnew(this.anio)
    },
    general (pId) {
      let cantidad = 0
      const general = this.objAnual.general
      for (let i = 0; i < general.length; i++) {
        if (pId === general[i].subestado_id) {
          cantidad = general[i].cantidad
          break
        }
      }
      return cantidad
    },
    mes (pMes) {
      this.objMes.data = null
      this.selMes = pMes
      this.rec_mesnew(this.anio, pMes.mes)
    }
  } // FIN DE METODOS
}
</script>
